import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import logo from './alalogo.png';
import {Col, Container, Image, Nav, Navbar, NavbarBrand, NavDropdown, Row} from 'react-bootstrap';
import {Envelope, Facebook, GlobeAmericas, Instagram, Search, Twitter, Youtube} from "react-bootstrap-icons";

const TopBanner = () => (
    <Container fluid id={"topBanner"}>
        <Container className={"marginSide"}>
            <Nav className={"justify-content-end"}>
                <Nav.Link className={"topLinks"}><Envelope></Envelope></Nav.Link>
                <Nav.Link className={"topLinks"}><Facebook></Facebook></Nav.Link>
                <Nav.Link className={"topLinks"}><Instagram></Instagram></Nav.Link>
                <Nav.Link className={"topLinks"}><Twitter></Twitter></Nav.Link>
                <Nav.Item id={"vl"} className="topLinks"></Nav.Item>
                <Nav.Link className={"topLinks"}><GlobeAmericas></GlobeAmericas></Nav.Link>
                <NavDropdown id={"dropdownMenu"} title={"English"} children={"helo"}></NavDropdown>
            </Nav>
        </Container>
    </Container>
);

const TopMenu = () => (
    <Navbar expand={"lg"}>
        <Container className={"pt-2 marginSide"}>
            <Nav className={"justify-content-start"}>
                <NavbarBrand>
                    <Image src={logo} height={"42px"}></Image>
                </NavbarBrand>
            </Nav>
            <Nav className={"justify-content-end"}>
                <Nav.Link className={"navTabs"}>STORES & MAP</Nav.Link>
                <Nav.Link className={"navTabs"}>Dining</Nav.Link>
                <Nav.Link className={"navTabs"}>Events</Nav.Link>
                <Nav.Link className={"navTabs"}>Hours & Location</Nav.Link>
                <Nav.Link className={"navTabs"}><Search></Search></Nav.Link>
            </Nav>
        </Container>
    </Navbar>
);

const MiddleImage = () => (
    <Container className={"marginSide pt-xl-5"}>
        <Image
            src={"https://www.alamoanacenter.com/content/dam/b2c/mall-campaigns/ala-moana-center/rebrand/115779_WebsiteRebrand_Dining.jpg"}></Image>
    </Container>
);

const DiningText = () => (
    <Container className={"marginSide text-center pt-5 pb-5"}>
        <h2 className={"diningHeader"}>Dining at Ala Moana Center</h2>
        <div>Ala Moana Center offers more than 160 dining options, from snacks and quick service to fine</div>
        <div> dining. Explore the restaurants throughout the Center and various eateries in the Makai Market</div>
        <div>Food Court and <strong>LANAI @ ALA MOANA CENTER.</strong></div>
        <br></br>
        <div>
            <strong>CLICK HERE</strong> to view restaurants currently open for Takeout, Delivery and Dine-In.
        </div>
    </Container>
);

const Card = ({cardImage, name, location, description}) => (
    <Container className={"card"}>
        <Image src={cardImage} className={"cardImage"}></Image>
        <div>{name}</div>
        <div>{location}</div>
        <div>{description}</div>
    </Container>
);


const CardBack = () => (
    <Container fluid id={"cardBackground"}>
        <Container className={"smallMarginSide"}>
            <Row>
                <Col className={"justify-content-start"}>
                    <h2>SIT DOWN RESTAURANTS</h2>
                </Col>
                <Col className={"justify-content-end"}>
                    <div className={"linkThing"}>VIEW ALL SIT DOWN RESTAURANTS</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card
                        cardImage={"https://gizmostorageprod.blob.core.windows.net/tenant-logos/1597707607454-assaggio.jpg"}
                        name={"Assagio"}
                        location={"Street Level 1, near Macy's"}
                        description={"Tantalize yourself with fine quality dining of Italian cuisine at an affordable price."}>
                    </Card>
                </Col>
                <Col>
                    <Card
                        cardImage={"https://gizmostorageprod.blob.core.windows.net/tenant-logos/1597707607454-assaggio.jpg"}
                        name={"Assagio"}
                        location={"Street Level 1, near Macy's"}
                        description={"Tantalize yourself with fine quality dining of Italian cuisine at an affordable price."}>
                    </Card>
                </Col>
                <Col>
                    <Card
                        cardImage={"https://gizmostorageprod.blob.core.windows.net/tenant-logos/1597707607454-assaggio.jpg"}
                        name={"Assagio"}
                        location={"Street Level 1, near Macy's"}
                        description={"Tantalize yourself with fine quality dining of Italian cuisine at an affordable price."}>
                    </Card>
                </Col>
            </Row>
        </Container>
    </Container>
);

const BottomBanner = () => (
    <Container fluid className={"text-center"}>
        <div id="pixlee_container">
            <iframe id="pixlee_widget_iframeb9bea792-557c-2192-c4c7-5ba7972952a7" width="100%" frameBorder="0"
                    title="Customer Photo gallery powered by Pixlee" allowFullScreen="" data-widget-id="4148418"
                    src="https://photos.pixlee.co/widget?widget_id=4148418&amp;api_key=1gtKcwtSemHqnvqmwCMQ&amp;parent_url=https%3A%2F%2Fwww.alamoanacenter.com%2Fen%2Fdining.html"
                    scrolling="no"></iframe>
            <a id="powered_by_pixleed8179957-c476-b9f3-0769-81d758f4b254" target="blank"
               title="Customer photo gallery powered by Pixlee"
               href="https://www.pixlee.com/?utm_source=Powered%20by%20Pixlee&amp;utm_campaign=Powered%20by%20Pixlee&amp;utm_medium=widget-main"
               rel="nofollow"><img
                id="pixlee-logo" alt="" src="https://assets.pixlee.com/images/logo.png"></img> by Pixlee</a>
        </div>
    </Container>
);

const PreFooter = () => (
    <Container className={"marginSide"}>
        <Nav className={"justify-content-center"}>
            <Nav.Link className={"navTabs mx-auto"}>Contact US</Nav.Link>
            <Nav.Link className={"navTabs mx-auto"}>feedback</Nav.Link>
            <Nav.Link className={"navTabs mx-auto"}>press room</Nav.Link>
            <Nav.Link className={"navTabs mx-auto"}>jobs</Nav.Link>
            <Nav.Link className={"navTabs mx-auto"}>gift cards</Nav.Link>
            <Nav.Link className={"navTabs mx-auto"}>faq</Nav.Link>
            <Nav.Link className={"navTabs mx-auto"}>lpr faq</Nav.Link>
        </Nav>
    </Container>
);

const Footer = () => (
    <Container fluid className={"bg-black text-white text-center"}>
        <Container className={"marginSide"}>
            <Row className={"justify-content-center"}>
            <Col xs={"auto"}><Facebook></Facebook></Col>
            <Col xs={"auto"}><Twitter></Twitter></Col>
            <Col xs={"auto"}><Instagram></Instagram></Col>
            <Col xs={"auto"}><Youtube></Youtube></Col>
        </Row>
            <Row className={"justify-content-center text-center"}>
                1450 Ala Moana Boulevard, Honolulu, HI 96814
                <br></br>
                +1-808-955-9517
            </Row>
            <Row className={"justify-content-center"}>
                CODE OF CONDUCT / TERMS / PRIVACY POLICY / YOUR PRIVACY CHOICES privacy options / COOKIES SETTINGS /
                ACCESSIBILITY
            </Row>
            <Row className={"justify-content-center"}>
                CHANGE LANGUAGE:
                日本語
                中文(简体)
                中文(繁体)
                한국어
            </Row>
            <hr className={"hl"}></hr>
            <Row className={"justify-content-center"}>
                <Col>OFFICE LEASING</Col>
                <Col>RETAIL LEASING</Col>
                <Col>ABOUT US</Col>
                <Col>ADVERTISING</Col>
            </Row>
            <Row className={"justify-content-center"}>
                ©2018-2023 Brookfield Properties All rights reserved.
            </Row></Container>
    </Container>
);

const AlaMoana = () => (
    <Container fluid className={"px-0"}>
        <TopBanner/>
        <TopMenu/>
        <MiddleImage/>
        <DiningText/>
        <CardBack/>
        <CardBack/>
        <CardBack/>
        <BottomBanner/>
        <PreFooter/>
        <Footer/>
    </Container>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AlaMoana/>
);